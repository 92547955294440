import { useEffect } from 'react';
import 'bootstrap';

import './bootstrap.css';
import './style.css';

function App() {
	const btnPressed = (btn) => {
		switch(btn){
			case "Q":
				console.log('Q pressed');
				document.getElementById('Q').play();
				return;
			case "W":
				console.log('W pressed');
				document.getElementById('W').play();
				return;
			case "E":
				console.log('E pressed');
				document.getElementById('E').play();
				return;
			case "A":
				console.log('A pressed');
				document.getElementById('A').play();
				return;
			case "S":
				console.log('S pressed');
				document.getElementById('S').play();
				return;
			case "D":
				console.log('D pressed');
				document.getElementById('D').play();
				return;
			case "Z":
				console.log('Z pressed');
				document.getElementById('Z').play();
				return;
			case "X":
				console.log('X pressed');
				document.getElementById('X').play();
				return;
			case "C":
				console.log('C pressed');
				document.getElementById('C').play();
				return;
			default:
				console.log('Not implemented '+btn);
				return;
		}
	}

	useEffect(() => {
		document.addEventListener('keypress',(event) => {
			console.log(event.key + ' keypressed');
			btnPressed(event.key.toUpperCase());
		});
	}, []);

  	return (
		<div className='d-flex justify-content-center'>
			<div id='drum-machine' className='bg-black p-1 rounded-3'>
				<div id='display'></div>
				<div className='px-0 py-1'>
					<div className='d-inline p-1'>
						<button id='padQ' className='drum-pad btn btn-cyan' onClick={() => btnPressed('Q')}>Q<audio id='Q' className='clip' src='' /></button>
					</div>
					<div className='d-inline p-1'>
						<button id='padW' className='drum-pad btn btn-gray' onClick={() => btnPressed('W')}>W<audio id='W' className='clip' src='' /></button>
					</div>
					<div className='d-inline p-1'>
						<button id='padE' className='drum-pad btn btn-purple' onClick={() => btnPressed('E')}>E<audio id='E' className='clip' src='' /></button>
					</div>
				</div>
				<div className='px-0 py-1'>
					<div className='d-inline p-1'>
						<button id='padA' className='drum-pad btn btn-pink' onClick={() => btnPressed('A')}>A<audio id='A' className='clip' src='' /></button>
					</div>
					<div className='d-inline p-1'>
						<button id='padS' className='drum-pad btn btn-red' onClick={() => btnPressed('S')}>S<audio id='S' className='clip' src='' /></button>
					</div>
					<div className='d-inline p-1'>
						<button id='padD' className='drum-pad btn btn-orange' onClick={() => btnPressed('D')}>D<audio id='D' className='clip' src='' /></button>
					</div>
				</div>
				<div className='px-0 py-1'>
					<div className='d-inline p-1'>
						<button id='padZ' className='drum-pad btn btn-yellow' onClick={() => btnPressed('Z')}>Z<audio id='Z' className='clip' src='' /></button>
					</div>
					<div className='d-inline p-1'>
						<button id='padX' className='drum-pad btn btn-white' onClick={() => btnPressed('X')}>X<audio id='X' className='clip' src='' /></button>
					</div>
					<div className='d-inline p-1'>
						<button id='padC' className='drum-pad btn btn-teal' onClick={() => btnPressed('C')}>C<audio id='C' className='clip' src='' /></button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
